

// require("./lib/modernizr-custom.js");

/**
 * static includes
 *
 */

require("jquery");
// require("./chunk/_polyfills.js");

// import {
//     log,
//     viewport,
//     debounce
// } from './chunk/_utils.js';



/**
 * close menu, if clicked on projekte in homepage
 */
document.querySelector('.menu-item-projekte').addEventListener( 'click', function(params) {
    document.querySelector('.hamburger').click();   
})
